import React from "react";
import { Layout } from "antd";
import { Route, Switch } from "react-router-dom";
import AppRoutes from "../config/AppRoutes";
import MainHeader from "../Common/MainHeader";
const { Content } = Layout;

const Application = () => {
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Content>
        <Layout>
          <MainHeader />
          <Switch>
            {AppRoutes.map((route) => (
              <Route
                exact={route.exact}
                path={route.path}
                component={route.component}
                key={route.key}
              />
            ))}
          </Switch>
        </Layout>
      </Content>
    </Layout>
  );
};

export default Application;
