import React, { useState, useEffect } from "react";
import { Col, Row, Space, Collapse, Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import categoryImg from "../../Images/icons/category.png";
import Footer from "../../Common/Footer";

export default function CategoryDetail() {
  const [open, setOpen] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [categoryList, setCategoryList] = useState([
    {
      id: 0,
      title: "Operations",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
    {
      id: 1,
      title: "Inspections",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
    {
      id: 2,
      title: "Flows",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
    {
      id: 3,
      title: "Item 3",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
    {
      id: 4,
      title: "Item 4",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
    {
      id: 5,
      title: "Item 5",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
    {
      id: 6,
      title: "Item 6",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
    {
      id: 7,
      title: "Item 7",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
    {
      id: 8,
      title: "Item 8",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
    {
      id: 9,
      title: "Item 9",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
    {
      id: 10,
      title: "Item 10",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
    {
      id: 11,
      title: "Item 11",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
    {
      id: 12,
      title: "Item 12",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
    {
      id: 13,
      title: "Item 13",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
    {
      id: 14,
      title: "Item 14",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
    {
      id: 15,
      title: "Item 15",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
    {
      id: 16,
      title: "Item 16",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
    {
      id: 17,
      title: "Item 17",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
    {
      id: 18,
      title: "Item 18",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
    {
      id: 19,
      title: "Item 19",
      modules: [
        { id: 0, title: "Sub Item 1" },
        { id: 1, title: "Sub Item 2" },
        { id: 2, title: "Sub Item 3" },
        { id: 3, title: "Sub Item 4" },
      ],
    },
  ]);

  useEffect(() => {
    const url = window.location.href;
    const urlObject = new URL(url);
    const categoryValue = urlObject.pathname.split("/").pop();
    setOpen(categoryValue);
    const idValue = urlObject.searchParams.get("id");
    setSelectedId(idValue);
  }, []);

  const ShowMedia = (record) => {
    return (
      <Col style={{ marginTop: "40px" }}>
        <h1 style={{ color: "#242E42", fontSize: "24px", lineHeight: "16px" }}>
          {record?.title}
        </h1>
        <p>{record?.description}</p>
        <div
          style={{
            position: "relative",
            paddingBottom: "51.70940170940172%",
            height: 0,
          }}
        >
          <iframe
            title="Loom Video"
            src={record?.embedUrl}
            frameBorder="0"
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: "5px",
            }}
          ></iframe>
        </div>
      </Col>
    );
  };

  return (
    <div style={{ background: "#FFF", padding: "10px" }}>
      <Row gutter={[16, 16]}>
        <Col span={4}>
          <Space
            direction="vertical"
            className="saleorderscroll"
            style={{ width: "100%" }}
          >
            {categoryList
              ?.sort((a, b) => (a.title < b.title ? -1 : 1))
              ?.map((record, index) => (
                <Collapse
                  bordered={false}
                  size={"small"}
                  hideSelectAll={false}
                  className="colltable-collapse"
                  defaultActiveKey={["0"]}
                  key={index}
                  accordion={"accordion"}
                  onChange={() => {
                    setOpen(index);
                  }}
                  activeKey={open}
                  expandIconPosition={"end"}
                  style={{ borderRadius: "5px" }}
                >
                  <Collapse.Panel
                    destroyInactivePanel
                    showArrow={true}
                    header={
                      <h1 style={{ fontWeight: "bold", color: "#242E42" }}>
                        <img src={categoryImg} alt="categoryImg" />{" "}
                        <span>{record?.title}</span>
                      </h1>
                    }
                    key={index}
                  >
                    {record?.modules?.map((record, index) => (
                      <div
                        onClick={() =>
                          (window.location.href =
                            "/details/" + open + "?id=" + record?.id)
                        }
                      >
                        <h1
                          key={index}
                          style={{
                            padding: "5px",
                            cursor: "pointer",
                            color:
                              selectedId === record?.id ? "#3A61A8" : "#242E42",
                          }}
                        >
                          {record?.title}
                        </h1>
                      </div>
                    ))}
                  </Collapse.Panel>
                </Collapse>
              ))}
          </Space>
        </Col>

        <Col span={20}>
          <Col>
            <Row justify={"space-between"}>
              <Col>
                <h1
                  style={{
                    fontSize: "28px",
                    color: "#242E42",
                    fontWeight: "normal",
                  }}
                >
                  <img
                    src={categoryImg}
                    alt="categoryImg"
                    style={{ marginRight: "5px" }}
                  />
                  Test
                </h1>
              </Col>
              <Col>
                <Button
                  style={{
                    borderRadius: "20px",
                    color: "#FFFFFF",
                    background: "#3A61A8",
                  }}
                >
                  Complete & Continue <RightOutlined />
                </Button>
              </Col>
            </Row>
            <p>
              Automate daily repetitive inspections, documentation, and
              compliance activity to seamlessly manage the entire airfield and
              improve FAA Part 139 or ICAO Annex 14 compliance.
            </p>
          </Col>

          {ShowMedia({
            title: "Video Section",
            description:
              "Automate daily repetitive inspections, documentation, and compliance activity to seamlessly manage the entire airfield and improve FAA Part 139 or ICAO Annex 14 compliance.",
            embedUrl:
              "https://www.loom.com/embed/b78b47ad35e540c3a8cb12f66bf49003?sid=95bdf06d-0179-456c-a770-4651a79e1045",
          })}

          {ShowMedia({
            title: "Image Section",
            description:
              "Automate daily repetitive inspections, documentation, and compliance activity to seamlessly manage the entire airfield and improve FAA Part 139 or ICAO Annex 14 compliance.",
            embedUrl: "https://www.canva.com/design/DAFoY3hOrrw/watch?embed",
          })}

          <Col style={{ marginTop: "40px" }}>
            <Footer />
          </Col>
        </Col>
      </Row>
    </div>
  );
}
