import React, { useEffect } from "react";
import { Route, useLocation, useHistory } from "react-router-dom";
import Application from "./Components/Application";
import Login from "./Login";
import { withAppContext } from "./Store/AppContext";

const Authenticated = ({ context }) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname !== "/" && location.pathname !== "/login" && localStorage.getItem("userLogin") !== "true") {
      history.push("/login");
    }
  }, [location.pathname, history]);

  return (
    <div style={{ background: "#f0f2f5", marginBottom: "10vw" }}>
      {location.pathname !== "/" && location.pathname !== "/login" && localStorage.getItem("userLogin") === "true" ? (
        <Application />
      ) : (
        <Route path="/" component={Login} />
      )}
    </div>
  );
};

export default withAppContext(Authenticated);
