import React, { Component } from "react";
export const AppContext = React.createContext("app");

const initialValue = {
  userLogin: false,
  loading: false,
  errorLoading: false,
  isDarkMode: false,
  userInfo: {},
};

export class AppProvider extends Component {
  state = initialValue;

  setUserLogin = (value) => {
    this.setState({ userLogin: value });
  };

  setUserInfo = (value) => {
    this.setState({ userInfo: value });
  };

  setLoading = (value) => {
    this.setState({
      loading: value,
    });
  };

  toggleTheme = () => {
    this.setState((prevState) => ({ isDarkMode: !prevState.isDarkMode }));
  };

  render() {
    const { userLogin, userInfo, loading, errorLoading, isDarkMode } =
      this.state;
    return (
      <AppContext.Provider
        value={{
          userLogin,
          userInfo,
          loading,
          errorLoading,
          isDarkMode,
          setUserInfo: this.setUserInfo,
          setUserLogin: this.setUserLogin,
          setLoading: this.setLoading,
          toggleTheme: this.toggleTheme,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export function withAppContext(GivenComponent) {
  return function wrapperComponent(props) {
    return (
      <AppContext.Consumer>
        {(state) => <GivenComponent {...props} context={state} />}
      </AppContext.Consumer>
    );
  };
}
