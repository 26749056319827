import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { AppProvider } from "./Store/AppContext";
import App from "./App";
import "./index.scss";
import "antd/dist/antd.min.css";
import "./Stylesheet/global.scss";
ReactDOM.render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.getElementById("root")
);
reportWebVitals();
