import React, { Component } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import Authenticated from "./Authenticated";
import { withAppContext } from "./Store/AppContext";
class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" component={Authenticated} />
        </Switch>
      </Router>
    );
  }
}

export default withAppContext(App);
