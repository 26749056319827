import Login from "../Login";
import Dashboard from "../Components/Dashboard/Dashboard";
import Categories from "../Components/Categories/Categories";
import CategoryDetail from "../Components/Categories/CategoryDetail";

const AppRoutes = [
  { key: "001", exact: true, path: "/", component: Login },
  { key: "002", exact: true, path: "/login", component: Login },
  { key: "004", exact: true, path: "/dashboard", component: Dashboard },
  { key: "005", exact: true, path: "/salesDemo", component: Categories },
  { key: "006", exact: true, path: "/details/:id", component: CategoryDetail },
];

export default AppRoutes;
