import React from "react";
import { Layout, Row, Col, Dropdown, Menu, Modal, Avatar } from "antd";
import {
  PoweroffOutlined,
  DownOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Auth } from "aws-amplify";
import Aerologo from "../Images/AerologoLight.png";
import { Link } from "react-router-dom";
import "../Stylesheet/_header.scss";

const { Header } = Layout;

export default function MainHeader() {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const out = () => {
    Modal.confirm({
      title: " Are You Sure",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => logout(),
      centered: true,
    });
  };

  const logout = async () => {
    try {
      await Auth.signOut()
        .then((res) => {
          localStorage.setItem("userDetails", JSON.stringify(""));
          localStorage.removeItem("userLogin");
          this.props.context.setUserDetails("");
          this.props.context.setUser("");
          this.props.context.setUserLogin(false);
          window.location.replace("/login");
        })
        .catch((error) => {
          console.log("error signing out: ", error);
          window.location.replace("/login");
        });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const menu = (
    <Menu style={{ marginTop: "-10px" }}>
      {/* <Menu.Item key="2" icon={<UserOutlined />}>
        <Link to="/#">Profile</Link>
      </Menu.Item> */}
      <Menu.Item
        key="4"
        icon={<PoweroffOutlined />}
        onClick={out}
        style={{
          color: "red",
        }}
      >
        Log Out
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="headerwar-header">
      <Row justify="space-between">
        <Col>
          <img src={Aerologo} alt="logo" style={{ width: "175px" }} />
        </Col>
        <Col>
          <Dropdown overlay={menu} placement="bottom">
            <div className="headerwar-div">
              {userDetails?.firstName !== undefined &&
              userDetails?.firstName !== null &&
              userDetails?.firstName !== "" ? (
                <Avatar size="medium" className="headerwar-avatar">
                  {userDetails?.firstName.charAt(0)}
                </Avatar>
              ) : (
                <Avatar
                  size="medium"
                  className="headerwar-avathar"
                  icon={<UserOutlined />}
                />
              )}
              <span style={{ color: "#FFF", marginLeft: "5px" }}>
                {userDetails?.firstName + " " + userDetails?.lastName}{" "}
                <DownOutlined />
              </span>
            </div>
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
}
