import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Space,
  Checkbox,
  Divider,
  Collapse,
  Button,
  Result,
  Progress,
  notification,
} from "antd";
import {
  BarsOutlined,
  AppstoreOutlined,
  RightOutlined,
  LeftOutlined,
  DownOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { withAppContext } from "../../Store/AppContext";
import { noAuthlist, noAuthupdate } from "../../api/Apis";
import appConfig from "../../config/AppConfig";
import categoryImg from "../../Images/icons/category.png";
import Footer from "../../Common/Footer";
import Loading from "../../Common/Loading";
import { RequestDemo } from "../../Common/DemoButtons";

const Categories = ({ context, history }) => {
  const [loading, setLoading] = useState(false);
  const [enableGrid, setEnableGrid] = useState(false);
  const [enableDemos, setEnableDemos] = useState(false);
  const [open, setOpen] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [previousId, setPreviousId] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [solutionList, setSolutionList] = useState([]);
  const [selectedModule, setSelectedModule] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSolutions, setSelectedSolutions] = useState([]);
  const [userActivityTracking, setUserActivityTracking] = useState([]);
  const [currentTime, setCurrentTime] = useState(1);
  const [userInfo, setUserInfo] = useState("");

  useEffect(() => {
    getMasterData();
  }, []);

  const getMasterData = async () => {
    setLoading(true);
    noAuthlist(appConfig["entityNames"]["getDemoData"], {}, true)
      .then((result) => {
        if (result.success) {
          let moduleInfo = [];
          let solutionInfo = [];
          result?.data?.map((i) => {
            if (i?.dataType === "modules") {
              moduleInfo.push(i);
            } else {
              solutionInfo.push(i);
            }
          });
          setSolutionList(solutionInfo);
          let sortedCategories = moduleInfo?.sort((a, b) =>
            parseInt(a?.sequenceId) < parseInt(b?.sequenceId) ? -1 : 1
          );
          setCategoryList(sortedCategories);
          setTimeout(() => {
            HandleAnalitics(sortedCategories, solutionInfo);
            setLoading(false);
          }, 2000);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
    const userDetails = await JSON.parse(localStorage.getItem("userDetails"));
    setUserInfo(userDetails);
  };

  const HandleModuleContinue = () => {
    setEnableDemos(false);
  };

  const HandleAnalitics = async (sortedCategories, solutionInfo) => {
    const userDetails = await JSON.parse(localStorage.getItem("userDetails"));
    let userAnalitics = userDetails?.analitics;
    let handlePreviousSolutions = [];

    if (userAnalitics !== undefined && userAnalitics !== null) {
      let userPreviouslySelectedSolutions =
        userAnalitics?.selectedSolutions?.length > 0
          ? userAnalitics?.selectedSolutions
          : [];
      if (userPreviouslySelectedSolutions?.length > 0) {
        userPreviouslySelectedSolutions.map((solution) => {
          handlePreviousSolutions.push(solution);
        });
        setSelectedSolutions(handlePreviousSolutions);
        setUserActivityTracking(userPreviouslySelectedSolutions);
      }

      let userPreviouslySelectedCategory =
        userAnalitics?.selectedCategories?.length > 0
          ? userAnalitics?.selectedCategories
          : [];

      if (userPreviouslySelectedCategory?.length > 0) {
        let initialCategories = sortedCategories.filter((item) =>
          userPreviouslySelectedCategory.includes(item.id)
        );

        let updatedList = [];
        initialCategories.map((e) => {
          let solutions = solutionInfo.filter((i) => i.moduleId === e.id);
          e["solutions"] = solutions;
          updatedList.push(e);
        });
        setSelectedCategories(updatedList);

        sortedCategories?.map((e, index) => {
          setLoading(false);
          if (sortedCategories.length === index + 1) {
            HandleInitialContinue(
              initialCategories,
              handlePreviousSolutions,
              userPreviouslySelectedSolutions
            );
          }
        });
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const HandleInitialContinue = (
    initialCategories,
    initialSelectedSolutions,
    initialUserActivityTracking
  ) => {
    initialCategories.map((i, index) => {
      if (i?.solutions?.length > 0) {
        let solutionId = i?.solutions[0]?.id;
        let title = i?.solutions[0]?.title;
        HandleSelectedDemos(
          index,
          0,
          solutionId,
          title,
          initialCategories,
          initialSelectedSolutions,
          initialUserActivityTracking
        );
        return;
      } else {
        notification["error"]({
          message: "Selected module doesn't have a solution",
        });
        return;
      }
    });
  };

  const onChange = (e, solutionInfo, initialCategories) => {
    let updatedList = [];
    let solutions = solutionInfo.filter((i) => i.moduleId === e.id);
    e["solutions"] = solutions;
    let itemIndex = initialCategories.findIndex((i) => i.id === e.id);
    if (itemIndex !== -1) {
      updatedList = initialCategories.filter((i) => i.id !== e.id);
    } else {
      updatedList = [...initialCategories, e];
    }
    setSelectedCategories(updatedList);
  };

  const HandleSelectedDemos = (
    moduleIndex,
    solutionIndex,
    id,
    title,
    initialCategories,
    initialSelectedSolutions,
    initialUserActivityTracking
  ) => {
    setCurrentIndex(solutionIndex);
    setOpen(moduleIndex);
    setSelectedModule(initialCategories[moduleIndex]);

    let presentId =
      id == undefined
        ? initialCategories[moduleIndex]?.solutions[solutionIndex]?.id
        : id;
    let prevId = selectedId !== null ? selectedId : id;
    if (previousId === null && selectedId === null) {
      setPreviousId(presentId);
      setSelectedId(presentId);
    } else {
      setPreviousId(prevId);
      setSelectedId(presentId);
    }

    if (presentId === undefined) {
      return;
    }

    let visitedList = initialSelectedSolutions;
    let activityList = initialUserActivityTracking;
    let index = activityList.findIndex((i) => i.id === id);

    let activity = {};
    if (index === -1) {
      visitedList.push(id);
      activity = {
        id: id,
        activityTime: 1,
        title: title,
        moduleId: initialCategories[moduleIndex]?.id,
      };
      activityList.push(activity);
    }

    let ind = activityList.findIndex((i) => i.id === prevId);
    let oldTime = activityList[ind].activityTime;
    let newTime = oldTime + currentTime;
    activityList[ind]["activityTime"] = newTime;

    setCurrentTime(0);
    setSelectedSolutions(visitedList);
    setUserActivityTracking(activityList);
    updateUserActivity(activityList);
    setTimeout(() => {
      timeRunner(0);
      setEnableDemos(true);
    }, 300);
  };

  const timeRunner = (timeSpent) => {
    setInterval(() => {
      timeSpent = timeSpent + 1;
      setCurrentTime(timeSpent);
    }, 1000);
  };

  const updateUserActivity = async (activityList) => {
    const userDetails = await JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails?.id === undefined) {
      console.log("invalid user details");
      return;
    }

    let selectedCategoryList = [];
    activityList.map((category) => {
      if (!selectedCategoryList.includes(category?.moduleId)) {
        selectedCategoryList.push(category?.moduleId);
      }
    });

    const data = {
      entityData: {
        analitics: {
          selectedSolutions: activityList,
          selectedCategories: selectedCategoryList,
        },
      },
    };

    noAuthupdate(
      appConfig["entityNames"]["updateAnalytics"],
      userDetails?.id,
      data,
      true
    )
      .then((result) => {
        if (result.success) {
          userDetails["analitics"] = result?.data?.analitics;
          context.setUserInfo(userDetails);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getSelectedSolutionCount = () => {
    let count = 0;
    selectedCategories.map((category) => {
      count = count + category?.solutions?.length;
    });
    return count;
  };

  const getPercentage = () => {
    let percentage = 0;
    percentage = (selectedSolutions.length / getSelectedSolutionCount()) * 100;
    return percentage;
  };

  const HandleItem = (c) => {
    return (
      <Checkbox
        checked={
          selectedCategories.findIndex((i) => i.id === c.id) !== -1
            ? true
            : false
        }
        onChange={() => onChange(c, solutionList, selectedCategories)}
        className="checkbox-blue"
      ></Checkbox>
    );
  };

  const ListItem = (c) => {
    return (
      <Col span={24} key={c?.id}>
        <Card
          hoverable
          bodyStyle={{ padding: "8px 5px 0px 5px" }}
          style={{ borderRadius: "5px" }}
          onClick={() => onChange(c, solutionList, selectedCategories)}
        >
          <Row justify={"space-between"}>
            <Col>
              <h1>
                <img src={categoryImg} alt="categoryImg" /> {c?.title}
              </h1>
            </Col>
            <Col justify={"center"} align={"middle"}>
              <div style={{ padding: "5px" }}>{HandleItem(c)}</div>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  };

  const CardItem = (c) => {
    return (
      <Col span={6} key={c?.id}>
        <Card
          hoverable
          bodyStyle={{ padding: "5px" }}
          style={{ borderRadius: "5px" }}
          onClick={() => onChange(c, solutionList, selectedCategories)}
        >
          <Col align={"end"}>{HandleItem(c)}</Col>
          <Col align={"middle"}>
            <img src={categoryImg} alt="categoryImg" />
          </Col>
          <Col align={"middle"}>
            <h1>{c?.title}</h1>
          </Col>
        </Card>
      </Col>
    );
  };

  const ShowModules = () => {
    return (
      <Col span={24}>
        <Row style={{ marginTop: "30px" }}>
          <Col offset={6} span={12}>
            <Row justify={"space-between"}>
              <Col>
                <h1
                  style={{
                    color: "#242E42",
                    fontSize: "28px",
                    textAlign: "center",
                  }}
                >
                  What modules are you interested in?
                </h1>
              </Col>

              <Col style={{ marginTop: "15px" }}>
                <Row gutter={[8, 8]}>
                  <Col>
                    <BarsOutlined
                      style={{
                        color: !enableGrid
                          ? appConfig.app_colors.blueDark
                          : appConfig.app_colors.greyLight,
                        fontSize: "20px",
                      }}
                      onClick={() => {
                        setEnableGrid(false);
                      }}
                    />
                  </Col>
                  <Divider type="vertical" style={{ marginTop: "5px" }} />
                  <Col>
                    <AppstoreOutlined
                      style={{
                        color: enableGrid
                          ? appConfig.app_colors.blueDark
                          : appConfig.app_colors.greyLight,
                        fontSize: "20px",
                      }}
                      onClick={() => {
                        setEnableGrid(true);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Card style={{ borderRadius: "5px" }}>
              <Row gutter={[8, 8]}>
                {categoryList?.map((c) =>
                  enableGrid ? CardItem(c) : ListItem(c)
                )}
              </Row>
            </Card>

            {selectedCategories?.length > 0 && (
              <Row
                justify={"center"}
                gutter={[16, 16]}
                style={{ marginTop: "20px" }}
              >
                <Col span={24}>
                  <Row justify={"center"}>
                    <Col align={"middle"}>
                      <Button
                        style={{
                          borderRadius: "20px",
                          color: "#FFFFFF",
                          background: "#3A61A8",
                        }}
                        onClick={() => {
                          HandleInitialContinue(
                            selectedCategories,
                            selectedSolutions,
                            userActivityTracking
                          );
                        }}
                      >
                        Continue <RightOutlined />
                      </Button>
                    </Col>
                  </Row>
                  <h1 style={{ textAlign: "center" }}>
                    Selected Modules {selectedCategories?.length}/
                    {categoryList?.length}
                  </h1>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    );
  };

  const ShowMedia = (record) => {
    return (
      <Col style={{ marginTop: "40px" }}>
        <h1 style={{ color: "#242E42", fontSize: "24px", lineHeight: "16px" }}>
          {record?.title}
        </h1>
        <p>{record?.description}</p>
        <div
          style={{
            position: "relative",
            paddingBottom: "51.70940170940172%",
            height: 0,
            background: "#f5f5f5",
          }}
        >
          <iframe
            title="Loom Video"
            src={record?.embedUrl}
            frameBorder="0"
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          ></iframe>
        </div>
      </Col>
    );
  };

  const ShowDemos = () => {
    return (
      <div style={{ background: "#FFF" }}>
        <Row>
          <Col span={4} style={{ borderRight: "2px solid #f5f5f5" }}>
            {/* <div style={{ padding: "10px 20px", backgroundColor: "#F4F7F9" }}>
              <Row justify={"space-between"}>
                <Col>
                  <h4 style={{ color: "#242E42", fontSize: "15px" }}>
                    {selectedCategories?.length} Modules .{" "}
                    {getSelectedSolutionCount()} Solutions
                  </h4>
                </Col>
                <Col>
                  <h4 style={{ color: "#31A870", fontSize: "15px" }}>
                    {getPercentage().toFixed(0)}%
                  </h4>
                </Col>
              </Row>
              <Row style={{ marginTop: "-2px" }}>
                <Progress
                  showInfo={false}
                  size="small"
                  strokeColor={"#31A870"}
                  trailColor={"#E6EAEE"}
                  percent={getPercentage()}
                />
              </Row>
            </div> */}

            {userInfo?.airportData?.airportLogo !== undefined && (
              <Col
                span={24}
                style={{ width: "100%", borderBottom: "2px solid #f5f5f5" }}
              >
                <Row justify={"center"}>
                  <Col align={"middle"}>
                    <div style={{ padding: "10px", margin: "5px" }}>
                      <img
                        src={userInfo?.airportData?.airportLogo}
                        alt="logo"
                        style={{ minHeight: "56px", maxHeight: "56px" }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            )}

            <div style={{ width: "100%" }}>
              {selectedCategories?.map((record, index) => (
                <Collapse
                  bordered={false}
                  size={"small"}
                  hideSelectAll={false}
                  className="collapse-header"
                  defaultActiveKey={["0"]}
                  key={index}
                  accordion={"accordion"}
                  onChange={() => {
                    setOpen(index);
                  }}
                  activeKey={open}
                  expandIconPosition={"end"}
                  expandIcon={({ isActive }) => (
                    <DownOutlined
                      rotate={isActive ? 180 : 0}
                      style={{
                        fontWeight: 700,
                        color: "#242E42",
                        marginTop: "-2px",
                        fontSize: "14px",
                      }}
                    />
                  )}
                >
                  <Collapse.Panel
                    className="collapse-body"
                    destroyInactivePanel
                    header={
                      <Row>
                        <Col>
                          <img src={categoryImg} alt="categoryImg" />
                        </Col>
                        <Col>
                          <h4
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "#242E42",
                              wordBreak: "break-word",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "200px",
                              overflow: "hidden",
                              marginTop: "2px",
                              marginLeft: "5px",
                            }}
                          >
                            {record?.title}
                          </h4>
                        </Col>
                      </Row>
                    }
                    key={index}
                  >
                    {record?.solutions?.map((record, indexj) => (
                      <div
                        onClick={() => {
                          HandleSelectedDemos(
                            index,
                            indexj,
                            record?.id,
                            record?.title,
                            selectedCategories,
                            selectedSolutions,
                            userActivityTracking
                          );
                        }}
                      >
                        <h5
                          key={indexj}
                          style={{
                            cursor: "pointer",
                            color:
                              selectedModule?.solutions[currentIndex]?.id ===
                              record?.id
                                ? "#3A61A8"
                                : "#242E42",
                            fontWeight:
                              selectedModule?.solutions[currentIndex]?.id ===
                              record?.id
                                ? "bold"
                                : "normal",
                            wordBreak: "break-word",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "220px",
                            overflow: "hidden",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {record?.title}
                        </h5>
                      </div>
                    ))}
                  </Collapse.Panel>
                </Collapse>
              ))}
            </div>

            <Col span={24}>
              <Row justify={"center"}>
                <Col align={"middle"}>
                  <Button
                    style={{
                      borderRadius: "20px",
                      color: "#FFFFFF",
                      background: "#3A61A8",
                    }}
                    onClick={() => {
                      setEnableDemos(false);
                    }}
                  >
                    <LeftOutlined /> Back
                  </Button>
                </Col>
              </Row>
            </Col>
          </Col>

          <Col span={20}>
            <Col style={{ padding: "10px 20px" }}>
              <Row>
                <Col span={16}>
                  <h1
                    style={{
                      fontSize: "20px",
                      color: "#242E42",
                      fontWeight: "normal",
                      cursor: "pointer",
                    }}
                  >
                    Hey {userInfo?.firstName + " " + userInfo?.lastName},
                  </h1>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s.
                  </p>
                </Col>
                <Col span={8}>
                  <Row
                    justify={"end"}
                    gutter={[16, 16]}
                    style={{ marginTop: "-8px" }}
                  >
                    <Col>
                      <RequestDemo />
                    </Col>
                    <Col>
                      <a
                        href={
                          userInfo?.airportData?.priceQuote !== undefined
                            ? userInfo?.airportData?.priceQuote
                            : ""
                        }
                        target="_blank"
                        download
                      >
                        <Button
                          type="primary"
                          style={{
                            marginTop: "25px",
                            borderRadius: "20px",
                            border: "1px solid #FFFFFF",
                            background: "#3A61A8",
                            color: "#FFFFFF",
                            fontWeight: "500",
                          }}
                        >
                          <DownloadOutlined
                            style={{ fontSize: "16px", fontWeight: "600" }}
                          />
                          Download Price Quote
                        </Button>
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Divider style={{ marginTop: "-11px", marginBottom: "15px" }} />

            <Col style={{ padding: "0px 20px" }}>
              <h1
                style={{
                  fontSize: "28px",
                  color: "#242E42",
                  fontWeight: "normal",
                  cursor: "pointer",
                }}
              >
                <img
                  src={categoryImg}
                  alt="categoryImg"
                  style={{ marginRight: "5px" }}
                />
                {selectedModule?.title}
              </h1>

              <h1
                style={{
                  color: "#242E42",
                  fontSize: "24px",
                  lineHeight: "16px",
                }}
              >
                {selectedModule?.solutions[currentIndex]?.title || ""}
              </h1>
              <p>
                {selectedModule?.solutions[currentIndex]?.description || ""}
              </p>
              {selectedModule?.solutions[currentIndex]?.videoUrl ===
                undefined &&
              selectedModule?.solutions[currentIndex]?.imageUrl ===
                undefined ? (
                <Result
                  status="404"
                  title="Solution Not Found"
                  subTitle="Sorry, related demo is not attached"
                />
              ) : selectedModule?.solutions[currentIndex]?.videoUrl !==
                undefined ? (
                ShowMedia({
                  title: selectedModule?.solutions[currentIndex]?.videoTitle,
                  description:
                    selectedModule?.solutions[currentIndex]?.videoDescription,
                  embedUrl: selectedModule?.solutions[currentIndex]?.videoUrl,
                })
              ) : (
                <div></div>
              )}

              {selectedModule?.solutions[currentIndex]?.imageTitle !==
                undefined &&
                ShowMedia({
                  title: selectedModule?.solutions[currentIndex]?.imageTitle,
                  description:
                    selectedModule?.solutions[currentIndex]?.imageDescription,
                  embedUrl: selectedModule?.solutions[currentIndex]?.imageUrl,
                })}

              <div style={{ marginTop: "40px" }}>
                <Footer />
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div>
      {enableDemos ? ShowDemos() : ShowModules()}
      <Loading enableLoading={loading} label={"Loading Demos..!"} />
    </div>
  );
};

export default withAppContext(withRouter(Categories));
