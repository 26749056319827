import { Drawer, Row, Col } from "antd";
import Lottie from "lottie-react";
import logo from "../Images/Aerologonobg.png";
import loading from "../Json/loading.json";

const Loading = (props) => {
  const { enableLoading, label } = props;
  const style = {
    width: "400px",
    height: "400px",
    borderRadius: "50%",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
    padding: "25px",
  };

  return (
    <div>
      <Drawer
        className="background-transparent"
        visible={enableLoading}
        closable={false}
        placement="top"
        width="100%"
        height="100%"
        size="large"
        style={{ backgroundColor: "transparent" }}
      >
        <div style={style}>
          <Row justify={"center"}>
            <Col>
              <div
                style={{
                  width: "165px",
                  height: "165px",
                  borderRadius: "50%",
                  backgroundColor: "#f5f5f5",
                  boxShadow: "1px 1px 1px rgba(0,0,0, 0.7)",
                }}
              >
                <div>
                  <img
                    src={logo}
                    alt="Aerologo"
                    style={{
                      width: "145px",
                      marginLeft: "10px",
                      marginTop: "10px",
                    }}
                  />
                </div>
                <Lottie
                  animationData={loading}
                  style={{
                    marginTop: "-173px",
                    marginLeft: "-17px",
                    height: "200px",
                    width: "200px",
                  }}
                />
              </div>
            </Col>
          </Row>

          <h1
            style={{
              textAlign: "center",
              textShadow: "1px 1px 1px rgba(255, 255, 255, 0.7)",
            }}
          >
            Please wait..!
          </h1>
          <h3
            style={{
              color: "#FFF",
              textAlign: "center",
              marginTop: "-18px",
              textShadow: "1px 1px 1px rgba(35, 46, 66, 0.7)",
            }}
          >
            {label}
          </h3>
        </div>
      </Drawer>
    </div>
  );
};
export default Loading;
