import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Form, Input, Button, notification, Row, Col } from "antd";
import Amplify from "aws-amplify";
import OtpInput from "react-otp-input";
import Aerologo from "./Images/Aerologo.png";
import { noAuthadd } from "./api/Apis";
import { withAppContext } from "./Store/AppContext";
import Loading from "./Common/Loading";
import AwsConfig from "./config/AwsConfigs";
import AppConfig from "./config/AppConfig";
Amplify.configure(AwsConfig);

const Login = ({ context, history }) => {
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");

  useEffect(() => {
    localStorage.setItem("userDetails", JSON.stringify(""));
    context.setUserInfo({});
    context.setUserLogin(false);
  }, []);

  const handleSubmit = async (e) => {
    setLoading(true);
    let phone = (e?.phone).includes("+") ? e?.phone : "+" + e?.phone;
    const data = {
      entityData: {
        pin: e?.password,
        phone: phone,
      },
    };
    noAuthadd(AppConfig["entityNames"]["loginUser"], data, false)
      .then((result) => {
        if (result.success) {
          localStorage.setItem("userDetails", JSON.stringify(result.data));
          localStorage.setItem("userLogin", true);
          context.setUserInfo(result.data);
          context.setUserLogin(true);
          if (
            result.data["id"] !== undefined &&
            result.data["id"] !== null &&
            result.data["id"] !== ""
          ) {
            setTimeout(() => {
              history.push("/salesDemo");
            }, 3000);
          } else {
            notification["error"]({
              message: "Invalid Login Credentials",
            });
            setLoading(false);
          }
        } else {
          notification["error"]({
            message: "Invalid Login Credentials",
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        notification["error"]({
          message: "Invalid Login Credentials",
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="admin-login-form">
        <div style={{ textAlign: "center", marginBottom: "24px" }}>
          <img src={Aerologo} alt="Aerologo" style={{ width: "40%" }} />
        </div>
        <div className="login-fields">
          <Form onFinish={handleSubmit}>
            <Row justify={"center"} style={{ marginTop: "40px" }}>
              <Col span={16}>
                <h4 style={{ color: "#aaa" }}>Phone Number</h4>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input a valid phone number",
                    },
                  ]}
                >
                  <Input
                    placeholder="Phone Number"
                    className="form-input-phone"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row justify={"center"}>
              <Col span={16} style={{ marginRight: "-5px" }}>
                <h4 style={{ color: "#aaa" }}>PIN Number</h4>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input 4 digit PIN" },
                  ]}
                >
                  <OtpInput
                    isInputNum={true}
                    value={otp}
                    onChange={(e) => setOtp(e)}
                    numInputs={4}
                    isDisabled={false}
                    className={"input_btn_style"}
                    inputStyle={"otp_input"}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row justify={"center"}>
              <Col span={16}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      backgroundColor: "#232e42",
                      height: "50px",
                      width: "100%",
                      borderRadius: "5px",
                      margin: "30px 0 30px 0",
                    }}
                  >
                    <h3 style={{ color: "white" }}>Login</h3>
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <p style={{ marginTop: "10px", fontSize: "16px", color: "#232e42" }}>
          Version 1.2.10.08
        </p>
      </div>
      <Loading
        enableLoading={loading}
        label={
          "Loading our Aero's, Simple Solutions for your Airport's Significant Problems"
        }
      />
    </div>
  );
};

export default withAppContext(withRouter(Login));
