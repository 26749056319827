import React from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

export function RequestDemo() {
  return (
    <Button
      type="primary"
      style={{
        marginTop: "25px",
        borderRadius: "20px",
        border: "1px solid #3A61A8",
        color: "#3A61A8",
        background: "#FFFFFF",
        fontWeight: "500",
      }}
      onClick={() => {
        const url = "https://calendly.com/aerosimple/demo?month=2024-02";
        window.open(url, "_blank");
      }}
    >
      Request in-person Demo
    </Button>
  );
}

export function DownloadQuote() {
  return (
    <Button
      type="primary"
      style={{
        marginTop: "25px",
        borderRadius: "20px",
        border: "1px solid #FFFFFF",
        background: "#3A61A8",
        color: "#FFFFFF",
        fontWeight: "500",
      }}
    >
      <DownloadOutlined style={{ fontSize: "16px", fontWeight: "600" }} />{" "}
      Download Price Quote
    </Button>
  );
}
