import { Call, ApiUrl, noAuthApiUrl, ParseGeneralResponse } from "./ApiUtils";

const queryString = require("query-string");

export function list(entityName, query = {}) {
  let url = ApiUrl("list/" + entityName);
  url = queryString.stringifyUrl({ url: url, query: query });
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}
export function getFreeTrailData(entityName, query = {}) {
  let url = ApiUrl(entityName);
  url = queryString.stringifyUrl({ url: url, query: query });
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function getSalesData(entityName, query = {}) {
  let url = ApiUrl(entityName);
  url = queryString.stringifyUrl({ url: url, query: query });
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function getDetails(entityName, id = null) {
  let url;
  if (id) url = ApiUrl("get/" + entityName + "/" + id);
  else url = ApiUrl("get/" + entityName);
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function add(entityName, data) {
  const url = ApiUrl("create/" + entityName);
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function update(entityName, id, data) {
  const url = ApiUrl("update/" + entityName + "/" + id);
  return Call(
    "put",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function deleteRecord(entityName, id) {
  const url = ApiUrl("delete/" + entityName + "/" + id);
  return Call(
    "delete",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function noAuthlist(entityName, query = {}, auth) {
  let url = noAuthApiUrl(entityName);
  url = queryString.stringifyUrl({ url: url, query: query });
  let isAuth = auth === true ? true : false;
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    isAuth
  );
}

export function noAuthgetDetails(entityName, id, auth) {
  const url = noAuthApiUrl(entityName + "/" + id);
  let isAuth = auth === true ? true : false;
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    isAuth
  );
}

export function noAuthGetInfo(entityName, auth) {
  const url = noAuthApiUrl(entityName);
  let isAuth = auth === true ? true : false;
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    isAuth
  );
}

export function noAuthadd(entityName, data, auth) {
  const url = noAuthApiUrl(entityName);
  let isAuth = auth === true ? true : false;
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    isAuth
  );
}

export function noAuthupdate(entityName, id, data, auth) {
  let isAuth = auth === true ? true : false;
  const url = noAuthApiUrl(entityName + "/" + id);
  return Call(
    "put",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    isAuth
  );
}
