import React from "react";
import { Col, Row } from "antd";
import { RequestDemo } from "./DemoButtons";

export default function Footer() {
  return (
    <div
      style={{
        background: "#E8F3FF",
        padding: "30px 35px 20px",
        marginTop: "30px",
      }}
    >
      <Row justify={"space-between"}>
        <Col>
          <h1 style={{ fontSize: "24px" }}>Have questions?</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur. Viverra morbi magnis
            interdum eget donec tincidunt et. Posuere enim eleifend ipsum diam.
          </p>
        </Col>
        <Col>{RequestDemo()}</Col>
      </Row>
    </div>
  );
}
